
import axios from 'axios'
import config from '@/config'
import { app } from '@/main.js'
import { PublicStore } from "@/stores/public";
import { setCookie, delCookie, getCookie } from '@/utils/cookie'
import { checkTokenExpired } from '@/utils/jwt';
// 创建axios实例
const service = axios.create({
    baseURL: config.BASEURL, // api的base_url
    timeout: 40000, // 请求超时时间,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
    },
})
let isTokenChecking = false; // 是否正在检查 token
var tokenCheckPromise = null; // 共享一个 promise，防止多个请求重复检查
// request拦截器
service.interceptors.request.use(async (configs) => {
    if(configs.url == '/api/v1/refreshToken') {
        return configs
    }
    if(configs.url == '/api/v1/login') {
        return configs
    }
    if(configs.url == '/api/v1/createUser') {
        return configs
    }
    if(configs.baseURL.indexOf('news.algo') > -1) {
        return configs
    }
    if(configs.url.indexOf('tgLogin') > -1) {
        return configs
    }
    try {
        const token = await checkAndRefreshToken();
        if (token) {
            configs.headers = {
                ...configs.headers,
                Authorization:`Bearer ${token}`
            }
        }
        return configs;
    } catch (error) {
        return Promise.reject(error);
    }
}, error => {
    Promise.reject(error)
})

var retry_times = {}
// respone拦截器
service.interceptors.response.use(
    response => {
        if (response.status == 200 || response.status == 201) {
            if (retry_times[response.config.url]) {
                retry_times[response.config.url] = 0
            }
            return response.data
        }
    },
    async error => {
        if (error.config.url == '/api/v1/refreshToken') {
            removeData()
            return
        }
        if(error.response && error.response.status == 401) {
            return
        }
        let errorMessage;
        if(error.response && error.response.data) {
            if(error.response.data.message || error.response.data.errors) {
                errorMessage = error.response.data.message || error.response.data.errors
            }else {
                errorMessage = error.message
            }
        }else {
            errorMessage = error.message
        }
        app.config.globalProperties.$message.error(errorMessage)
        return Promise.reject(error)
    }
)
async function accessToken() {
    delCookie('access_token')
    const refresh_token = localStorage.getItem('refresh_token')
    if (!refresh_token) {//refresh_token 不存在
        removeData()
        return
    }
    try {
        const response = await service.post('/api/v1/refreshToken', {
            refreshToken: refresh_token
        });
        if (response && response.data.accessToken) {
            setCookie("access_token", response.data.accessToken)
            localStorage.setItem('access_token', response.data.accessToken)
            return response.data.accessToken
        }

    } catch (error) {
        removeData()
        Promise.reject(error)
    }
}

const removeData = () => {
    delCookie('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('access_token')
    const publicStore = PublicStore();
    publicStore.setIsLogin(false)
    location.href = "/login"
}

const checkAndRefreshToken = async () => {
    let token = getCookie("access_token")
    // 如果正在检查 token，等待现有的检查完成
    if (isTokenChecking && tokenCheckPromise) {
      return tokenCheckPromise;
    }
    isTokenChecking = true;
  
    tokenCheckPromise = new Promise(async (resolve, reject) => {
      if (checkTokenExpired(token)) {
        try {
          token = await accessToken();
          resolve(token); // 刷新成功，返回新的 token
        } catch (error) {
          reject('JWT 已过期，无法刷新，请重新登录');
        }
      } else {
        resolve(token); // token 未过期
      }
    });
  
    // 等待检查完成后再恢复请求
    try {
      token = await tokenCheckPromise;
    } finally {
      isTokenChecking = false; // 重置状态
      tokenCheckPromise = null;
    }
  
    return token;
};

export default service