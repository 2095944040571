<template>
  <!-- temporary：默认不显示，点击展开，适用移动端 -->
  <!-- permanent，保持可见性，否则窄屏不显示 -->
  <!-- rail：窄的，否则默认展开 -->
  <!-- expand-on-hover： hover 展开 -->
  <v-navigation-drawer
    class="drawer-box"
    temporary
    absolute
    v-model="showDrawer"
    @update:modelValue="updateModel"
  >
    <v-list>
      <v-list-item title="Algo724">
        <template v-slot:prepend>
          <v-img :src="logo" :width="30" @click="clickCount++" class="mr-4"></v-img>
        </template>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list density="compact" nav>
      <v-list-item
        height="50"
        title="Home"
        value="Home"
        @click="$router.push('/m/home')"
      >
        <template v-slot:prepend>
          <v-img :src="home" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <v-list-item
        height="50"
        title="Trade"
        value="Trade"
        @click="$router.push('/trade')"
      >
        <template v-slot:prepend>
          <v-img :src="trade" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <v-list-item
        height="50"
        title="Portfolio"
        value="Portfolio"
        @click="$router.push('/portfolio')"
      >
        <template v-slot:prepend>
          <v-img :src="portfolio" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <!-- <v-list-item
        height="50"
        title="AAVE"
        value="aave"
        @click="$router.push('/aave')"
      >
        <template v-slot:prepend>
          <v-img :src="aave" :width="22" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <v-list-item
        height="50"
        title="Uniswap"
        value="uniswap"
        @click="$router.push('/uniswap')"
      >
        <template v-slot:prepend>
          <v-img :src="uniswap" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item> -->
      <v-list-item
        height="50"
        title="Webhook"
        value="Webhook"
        @click="$router.push('/webhook')"
      >
        <template v-slot:prepend>
          <v-img :src="webhook" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <v-list-item
        height="50"
        title="Account"
        value="Account"
        @click="$router.push('/account')"
      >
        <template v-slot:prepend>
          <v-img :src="bank" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <v-list-item
        height="50"
        title="Plan"
        value="Plan"
        @click="$router.push('/plan')"
      >
        <template v-slot:prepend>
          <v-img :src="plan" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <v-list-item
        height="50"
        title="Setting"
        value="Setting"
        @click="$router.push('/setting')"
      >
        <template v-slot:prepend>
          <v-img :src="setting" :width="24" class="mr-5"></v-img>
        </template>
      </v-list-item>
      <v-list-item
        height="50"
        title="Logout"
        value="Logout"
        @click="logoutClick"
      >
        <template v-slot:prepend>
          <v-img :src="logout" :width="22" class="mr-5" style="margin-left: 2px;"></v-img>
        </template>
      </v-list-item>
    </v-list>
    <!-- <div class="switch-account-box pa-3">
      <div class="switch-content d-flex align-center">
        <div class="item" :class="!isDemo && 'active'" @click="changeAccountType('REAL')">REAL</div>
        <div class="item" :class="isDemo && 'active'" @click="changeAccountType('DEMO')">DEMO</div>
      </div>
    </div> -->
    <GlobalDialog
      :is-show="_data.showDialog"
      :title="_data.tipTitle"
      :text="_data.tipText"
      :confirmText="_data.confirmText"
      @confirm="logOutConfirm"
      @close="_data.showDialog = false"
    />
  </v-navigation-drawer>
</template>
  
<script setup>
import logo from "../../../public/images/logo.png";
import home from "../../../public/images/sidebar/home.png";
import trade from "../../../public/images/sidebar/trade.png";
import portfolio from "../../../public/images/sidebar/portfolio.png";
import bank from "../../../public/images/sidebar/account.png";
import setting from "../../../public/images/sidebar/setting.png";
import webhook from "../../../public/images/sidebar//webhook.png";
import plan from "../../../public/images/sidebar/plan.png";
// import aave from "../../../public/images/sidebar/aave.png";
// import uniswap from "../../../public/images/sidebar/uniswap.png";
import logout from "../../../public/images/sidebar/logout.png";
import GlobalDialog from "@/components/GlobalDialog.vue";
import { defineProps, watch, ref, computed, defineEmits, reactive,getCurrentInstance } from "vue";
import { PublicStore } from "@/stores/public";
const publicStore = PublicStore();
import { delCookie } from "@/utils/cookie";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();
const emits = defineEmits(["sidebar:update"]);
const props = defineProps({
  showDrawer: {
    type: Boolean,
    default: () => false,
  },
});
const clickCount = ref(0)
const _data = reactive({
  showDialog: false,
  tipTitle: "",
  tipText: "",
  confirmText: "Confirm",
});
const isDemo = computed(() => {
  return publicStore.isDemo
})
const _showDrawer = computed(() => {
  return props.showDrawer;
});
let showDrawer = ref(false);
watch(_showDrawer, (v) => {
  showDrawer.value = v;
});
watch(clickCount,(count) => {
  if(count >= 8) {
    clickCount.value = 0;
    router.push('/blackmagic');
  }
})
function updateModel() {
  emits("sidebar:update");
}
function logoutClick() {
  _data.showDialog = true;
  _data.tipTitle = "Logout";
  _data.tipText = "Are you sure you want to logout?";
}
function logOutConfirm() {
  publicStore.setIsLogin(false);
  publicStore.setReferralCode("");
  delCookie("access_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  _data.showDialog = false;
  router.push("/login");
}
function changeAccountType(val) {
  let isDemo = val == "DEMO" ? true : false;
  publicStore.setIsDemo(isDemo);
  proxy.$message.success(`You have switched to ${val} mode!`);
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  left: 0 !important;
  position: fixed !important;
  // z-index: 100000000 !important;
}
.switch-account-box {
  position: fixed;
  bottom:20px;
  width: 100%;
  
  overflow: hidden;
  .switch-content {
    border-radius: 6px ;
    // border: 1px solid #111;
  box-shadow: 0.3rem 0.3rem 0.3rem #0a0a0a,
        -0.2rem -0.2rem 0.4rem #292d34;
  }
  
  .item {
    flex:1;
    height: 40px;
    text-align: center;
    line-height: 40px;
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0px 6px 6px 0;
    }
    &.active {
      background-color: #fff;
      color: #111;
    }
  }
}
</style>
  