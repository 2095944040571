<template>
  <!-- temporary：默认不显示，点击展开，适用移动端 -->
  <!-- permanent，保持可见性，否则窄屏不显示 -->
  <!-- rail：窄的，否则默认展开 -->
  <!-- expand-on-hover： hover 展开 -->
  <v-navigation-drawer rail expand-on-hover permanent color="#171717" @update:rail="updateRail">
    <div class="card-box">
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <div class="d-flex align-center">
              <div class="sidebar-title">Algo724</div>
              <div class="vip-tag" v-if="isSubscription">Pro</div>
            </div>
          </v-list-item-title>
          <template v-slot:prepend>
            <v-img :src="logo" :width="28" @click="clickCount++" class="mr-7"></v-img>
          </template>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <p class="sub-title" v-if="!isRail">GENERAL</p>
        <v-list-item
          height="50"
          title="Home"
          value="Home"
          @click="$router.push('/home')"
        >
          <template v-slot:prepend>
            <v-img :src="home" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          id="trade_sidebar"
          height="50"
          title="Trade"
          value="Trade"
          @click="goTrade"
        >
          <template v-slot:prepend>
            <v-img :src="trade" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          id="portfolio_sidebar"
          height="50"
          title="Portfolio"
          value="Portfolio"
          @click="portfolioClick"
        >
          <template v-slot:prepend>
            <v-img :src="portfolio" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <p class="sub-title" v-if="!isRail">EXTRA</p>
        <!-- <v-list-item
          id="lens_sidebar"
          height="50"
          title="Lens"
          value="Lens"
          @click="$router.push('/lens')"
        >
          <template v-slot:prepend>
            <v-img :src="lens" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item> -->
        <v-list-item
          id="webhook_sidebar"
          height="50"
          title="Webhook"
          value="Webhook"
          @click="$router.push('/webhook')"
        >
          <template v-slot:prepend>
            <v-img :src="webhook" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <p class="sub-title" v-if="!isRail">ACCOUNT</p>
        <v-list-item
          id="account_sidebar"
          height="50"
          title="Account"
          value="Account"
          @click="$router.push('/account')"
        >
          <template v-slot:prepend>
            <v-img :src="bank" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          id="plan_sidebar"
          height="50"
          title="Plan"
          value="Plan"
          @click="$router.push('/plan')"
        >
          <template v-slot:prepend>
            <v-img :src="plan" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
        
        <v-list-item
          id="setting_sidebar"
          height="50"
          title="Setting"
          value="Setting"
          @click="$router.push('/setting')"
        >
          <template v-slot:prepend>
            <v-img :src="setting" :width="27" class="mr-7"></v-img>
          </template>
          <template v-slot:append>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import logo from "../../../public/images/logo.png";
import home from "../../../public/images/sidebar/home.png";
import trade from "../../../public/images/sidebar/trade.png";
import portfolio from "../../../public/images/sidebar/portfolio.png";
import bank from "../../../public/images/sidebar/account.png";
import setting from "../../../public/images/sidebar/setting.png";
import webhook from "../../../public/images/sidebar/webhook.png";
import plan from "../../../public/images/sidebar/plan.png";
// import lens from "../../../public/images/sidebar/lens.png"


import { PublicStore } from "@/stores/public";
import router from "@/router";
import { ref,computed,watch } from "vue";
const clickCount = ref(0)
const publicStore = PublicStore()
const isRail = ref(true);
const isSubscription = computed(() => {
  if(!publicStore.userInfo) {
    return false;
  }
  return publicStore.userInfo.stripeSubscription && publicStore.userInfo.stripeSubscription.length > 0;
})
function updateRail(v) {
  isRail.value = v;
}
function goTrade() {
  let tradeUrl = localStorage.getItem("trade-url");
  if(tradeUrl) {
    router.push(tradeUrl)
  }else {
    router.push('/trade-major')
  }
}
function portfolioClick() {
  const mode = localStorage.getItem("portfolio-mode");
  if(mode == 'card') {
    router.push('/portfolio-cards')
  }else {
    router.push('/portfolio')
  }
}

watch(clickCount,(count) => {
  if(count >= 8) {
    clickCount.value = 0
    router.push('/blackmagic')
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/fonts/poppins/font.css";
::v-deep .v-list-item--one-line {
  min-height: 47px !important;
}
::v-deep .v-list-item {
  &:hover {
    .v-img,i {
      animation: swing ease-in-out 0.5s 1 alternate;
    }
    .v-list-item-title {
      color: #fff;
    }
  }
}
::v-deep .v-list-item-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  
  line-height: 20px;
  color: #b8b8b8;
}
.sidebar-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
}
.sub-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 10px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #888888;
  margin-bottom:6px;
  margin-top:10px;
  padding-left: 4px;
}
@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(16deg);
  }

  40% {
    transform: rotate(-16deg);
  }

  60% {
    transform: rotate(8deg);
  }

  80% {
    transform: rotate(-8deg);
  }
}
.vip-tag {
  font-size: 12px;
  padding: 1px 10px;
  background: radial-gradient(#111a39, 67.5%, #4c87de) padding-box,
              radial-gradient(#7fabe6, 35%, rgba(74, 174, 154, 0) 70%) 50% 0 / 80% 50% repeat-y;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  margin-left: 8px;
  font-weight: 600;
  margin: 0 auto;
}
</style>
