<template>
  <v-layout class="rounded rounded-md">
    <SidebarInfo :showDrawer="showInfoDrawer" @sidebarinfo:update="sidebarInfoUpdate"/>
    <div v-if="clientWidth > 768">
        <Sidebar v-if="publicStore.isLogin" />
      </div>
      <div v-else>
        <SidebarMobile
          :showDrawer="showDrawer"
          v-if="publicStore.isLogin"
          @sidebar:update="sidebarUpdate"
          
        />
      </div>
      <v-app-bar>
        <Header @sidebar:update="sidebarUpdate" @sidebarinfo:update="sidebarInfoUpdate"/>
      </v-app-bar>
      <div v-if="clientWidth <= 768 && publicStore.isLogin">
        <BottomNavigation />
      </div>
      
      <v-main class="main-box">
        <router-view />
        <Footer v-if="publicStore.isLogin && clientWidth > 768" />
        <NewsPopUps v-if="showPopup" :pop-data="popData" @popup:update="popupUpdate"/>
      </v-main>
  </v-layout>
</template>

<script setup>
import Header from "./components/layout/Header.vue";
import Sidebar from "./components/layout/Sidebar.vue";
import SidebarMobile from "./components/layout/SidebarMobile.vue";
import SidebarInfo from "./components/SidebarInfo.vue"
import BottomNavigation from "./components/layout/BottomNavigation.vue";
import NewsPopUps from "./components/NewsPopUps.vue"
import Footer from "./components/Footer.vue";
import Socket from "@/servers/_websocket.js"
import config from "./config";
import { PublicStore } from "@/stores/public";
import { getCookie } from "@/utils/cookie";
import { onMounted, ref } from "vue";
import { useDisplay } from 'vuetify'
const { width } = useDisplay()
const publicStore = PublicStore();
const showDrawer = ref(false);
const showInfoDrawer = ref(false);
const showPopup = ref(false)
let popData = {
  title:"",
  content:""
}
if (getCookie("access_token")) {
  publicStore.getUserInfoFuc(); //更新用户数据
  // publicStore.getReferralCode(); //更新用户邀请码
}
const clientWidth = ref(0);
// const showLoadScreen = ref(true)
onMounted(() => {
  const socket = new Socket(config.WS_URL);
  publicStore.setSocket(socket)
  clientWidth.value = document.body.clientWidth;
  window.addEventListener("resize", resizeFuc);

  // const hawkeyeSocket = new Socket(config.HAWKEYE_WS_URL)
  // publicStore.setHawkeyeSocket(hawkeyeSocket)
  // setTimeout(() => {
  //   if(publicStore.isLogin) {
  //     getNews()
  //   }
  //   loopNews()
  // },3000)
});
function resizeFuc() {
  clientWidth.value = document.body.clientWidth;
  publicStore.setClientWidth(document.body.clientWidth);
}
function sidebarUpdate() {
  showDrawer.value = !showDrawer.value;
}
function sidebarInfoUpdate() {
  showInfoDrawer.value = !showInfoDrawer.value;
}
function popupUpdate() {
  showPopup.value = false
}

function loopNews() {
  setInterval(() => {
    if(publicStore.isLogin) {
      getNews()
    }
  },30000)
}
async function getNews() {
  let res = await publicStore.theblockbeatsNews({
    page:1,
    size:10,
    type:'news',
    lang:'en'
  })
  if(res.status == 0) {
    const lastNewsid = localStorage.getItem('last-news-id');
    const lastNewsidPop = localStorage.getItem('last-news-id-pop');
    if(lastNewsid && lastNewsid == res.data.data[0].id) {
      publicStore.setHaveNewNews2(false)
    }else {
      publicStore.setHaveNewNews2(true)
    }
    if(lastNewsidPop && lastNewsidPop == res.data.data[0].id) {
      publicStore.setHaveNewNews(false)
    }else {
      if(width.value > 768) {
        popData = res.data.data[0]
        showPopup.value = true
        publicStore.setHaveNewNews(true)
      }
    }
    localStorage.setItem("last-news-id-pop",res.data.data[0].id)
  }
}
</script>

<style lang="scss">
@import "./assets/fonts/Inter/font.css";
@import "./assets/scss/font.scss";
@import "./assets/scss/_common.scss";
.bg-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: -1;
}
#app {
  background: #000;
  //   height: 100vh;
  overflow-y: auto;
  font-family: Inter;
  // background-image: url("../public/images/background.png");
  span,
  p,
  div,
  label {
    font-family: Inter;
  }
}

@media (max-width: 768px) {
  .main-box {
    padding: 64px 0 0 0 !important;
  }
  .pc-sidebar {
    width: 0;
    display: none;
  }
  .mobile-sidebar {
    // display: block;
  }
}
@media (min-width: 768px) {
  .main-box {
    padding: 64px 4px 0px 55px !important;
  }
  .pc-sidebar {
    // display: block;
  }
  .mobile-sidebar {
    display: none;
  }
}
</style>
