<template>
  <div class="header-component">
    <div class="pc-header">
      
      <div class="pc-content d-flex justify-end align-center pl-4 pr-4">
        <a
          target="_black"
          class="font-primary-14 link"
          href="https://www.algo724.com/blog-pages/blog"
          >BLOG</a
        >
        <a
          target="_black"
          class="font-primary-14 link ml-8 mr-6"
          href="https://t.me/algo724"
          >TELEGRAM</a
        >
        <template v-if="isLogin">
          <v-btn class="rounded-pill ml-4" @click="_data.showInvite = true">
            <v-icon class="mr-1">mdi-account-multiple-plus</v-icon>
            <div class="invite-text text-capitalize" data-text="Invite friends">
              Invite friends
            </div>
          </v-btn>
          <v-menu offset-y location="bottom" transition="scale-transition">
            <template v-slot:activator="{ props }">
              <button class="user-name" v-bind="props" v-if="isSubscription">
                <div class="d-flex align-center">
                  💎 {{ userInfo.userName }}
                </div>
                <span class="wrap" aria-hidden="true"><span class="particle" style="--a: -45deg; --x: 53%; --y: 15%; --d: 4em; --f: .7; --t: .15"></span><span class="particle" style="--a: 150deg; --x: 40%; --y: 70%; --d: 7.5em; --f: .8; --t: .08"></span><span class="particle" style="--a: 10deg; --x: 90%; --y: 65%; --d: 7em; --f: .6; --t: .25"></span><span class="particle" style="--a: -120deg; --x: 15%; --y: 10%; --d: 4em"></span><span class="particle" style="--a: -175deg; --x: 10%; --y: 25%; --d: 5.25em; --f: .6; --t: .32"></span><span class="particle" style="--a: -18deg; --x: 80%; --y: 25%; --d: 4.75em; --f: .5; --t: .4"></span><span class="particle" style="--a: -160deg; --x: 30%; --y: 5%; --d: 9em; --f: .9; --t: .5"></span><span class="particle" style="--a: 175deg; --x: 9%; --y: 30%; --d: 6em; --f: .95; --t: .6"></span><span class="particle" style="--a: -10deg; --x: 89%; --y: 25%; --d: 4.5em; --f: .55; --t: .67"></span><span class="particle" style="--a: -140deg; --x: 40%; --y: 10%; --d: 5em; --f: .85; --t: .75"></span><span class="particle" style="--a: 90deg; --x: 45%; --y: 65%; --d: 4em; --f: .5; --t: .83"></span><span class="particle" style="--a: 30deg; --x: 70%; --y: 80%; --d: 6.5em; --f: .75; --t: .92"></span></span>
              </button>
              <v-btn
                height="34"
                variant="outlined"
                color="hsla(0,0%,100%,.12)"
                text
                prepend-icon="mdi-account"
                class="rounded-pill ml-4"
                v-bind="props"
                v-else
              >
                <template v-slot:prepend>
                  <v-avatar size="32" width="30" height="30">
                    <v-icon color="#fff">mdi-account</v-icon>
                  </v-avatar>
                  <p class="mb-0 pr-4 btn-label text-lowercase">
                    {{ userInfo.userName }}
                  </p>
                </template>
                
              </v-btn>
            </template>

            <v-card class="menu-content-box" color="#121212" width="360">
              <div class="content-box pt-5 pb-5">
                <div class="d-flex justify-center">
                  <div class="avatar-box">
                    {{
                      userInfo &&
                      userInfo.userName &&
                      userInfo.userName.slice(0, 1).toUpperCase()
                    }}
                  </div>
                  
                  
                </div>
                <p class="text-center font-weight-bold mt-2">
                  {{ userInfo.userName }}
                </p>
                <div class="d-flex justify-center">
                  <div class="vip-tag" v-if="isSubscription">
                    Pro
                  </div>
                </div>
                <div class="pa-4 pt-2 pb-2 mt-4">
                  <div class="code-box">
                    <div class=" pa-3">
                      <div class="">
                        <p class="font-primary-16 mb-1">Email</p>
                        <p class="font-secondary-12 mb-0">
                          {{ userInfo.email }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <v-divider class="mt-4 mb-4"></v-divider>
                <div
                  class="row-menu d-flex justify-space-between align-center"
                  v-for="item in headMenu"
                  :key="item.name"
                  @click="toPageClick(item.url)"
                >
                  <div class="d-flex align-center">
                    <img :src="item.icon" width="25" alt="" />
                    <span class="menu-text ml-3">{{ item.title }}</span>
                  </div>
                </div>
                <v-divider class="mt-4 mb-4"></v-divider>
                <div
                  class="row-menu d-flex justify-space-between align-center"
                  @click="logoutClick"
                >
                  <div class="d-flex align-center">
                    <img
                      src="../../../public/images/sidebar/logout.png"
                      width="23"
                      style="margin-left: 2px;"
                      alt=""
                    />
                    <span class="menu-text ml-3">Logout</span>
                  </div>
                </div>
              </div>
            </v-card>
          </v-menu>
          
          <v-btn
            class="start-new-btn rounded-pill ml-4"
            color="hsla(0,0%,100%,.12)"
            text
            variant="outlined"
            height="34"
            @click="createBotClick"
          >
            <span style="color: #fff" class="d-flex align-center">
              <v-icon small class="mr-1"> mdi-robot </v-icon>
              <p class="mb-0 btn-label">{{ $t("trade.createBot.text2") }}</p>
            </span>
          </v-btn>
          
          <!-- <v-btn class="rounded-pill create-webhook ml-4" height="34" variant="outlined" @click="$router.push('/webhook')">+ Create Webhook</v-btn> -->
          <!-- <v-btn class="text-none" stacked @click="sidebarInfoClick">
            <v-badge color="error" dot v-if="publicStore.haveNewNews2">
              <img src="/images/setting/notice.png" width="30" alt="">
            </v-badge>
            <img v-else src="/images/setting/notice.png" width="30" alt="">
          </v-btn> -->
        </template>
      </div>
    </div>
    <div
      class="mobile-header d-flex justify-space-between align-center pl-4 pr-4"
    >
      <template v-if="isLogin">
        <v-icon @click="sidebarClick">mdi-menu</v-icon>
        <!-- <v-badge color="error" dot v-if="publicStore.haveNewNews2" @click="sidebarInfoClick">
          <img src="/images/setting/notice.png" width="30" alt="">
        </v-badge>
        <img v-else @click="sidebarInfoClick" src="/images/setting/notice.png" width="30" alt=""> -->
      </template>
    </div>
    <GlobalDialog
      :is-show="_data.showDialog"
      :title="_data.tipTitle"
      :text="_data.tipText"
      :confirmText="_data.confirmText"
      @confirm="logOutConfirm"
      @close="_data.showDialog = false"
    />
    <InviteDialog v-model="_data.showInvite"/>
  </div>
</template>

<script setup>
import GlobalDialog from "@/components/GlobalDialog.vue";
import InviteDialog from  "@/components/InviteDialog.vue";
import ClipboardJS from "clipboard";
import {
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  defineEmits
} from "vue";
import { PublicStore } from "@/stores/public";
const publicStore = PublicStore();
import { useRouter } from "vue-router";
import { delCookie } from "@/utils/cookie.js";
const emits = defineEmits(["sidebar:update"]);
const router = useRouter();
const { proxy } = getCurrentInstance();

const isSubscription = computed(() => {
  if(!publicStore.userInfo) {
    return false;
  }
  return publicStore.userInfo.stripeSubscription && publicStore.userInfo.stripeSubscription.length > 0;
})
const headMenu = [
  {
    title: "Account",
    name: "Account",
    icon: require("../../../public/images/sidebar/account.png"),
    url: "/account",
  },
  {
    title: "Setting",
    name: "Setting",
    icon: require("../../../public/images/sidebar/setting.png"),
    url: "/setting",
  },
];
onMounted(() => {
  var clipboard = new ClipboardJS(".copyBtn");
  clipboard.on("success", (e) => {
    proxy.$message.success("Copy success!");
    e.clearSelection();
  });
  clipboard.on("error", () => {
    proxy.$message.error("Copy faild! ");
  });
});
const _data = reactive({
  isDemo: false,
  showDialog: false,
  tipTitle: "",
  tipText: "",
  confirmText: "Confirm",
  showInfoBar:false,
  showInvite:false
});
const registerLink = computed(() => {
  return (
    window.location.origin +
    "/register?referral_code=" +
    publicStore.referral_code
  );
});
const isLogin = computed(() => {
  return publicStore.isLogin;
});
const userInfo = computed(() => {
  return publicStore.userInfo;
});
function createBotClick() {
  if (location.pathname != "/home") {
    router.push("/home");
    setTimeout(() => {
      publicStore.setCreateBot(true);
    }, 2000);
  } else {
    publicStore.setCreateBot(true);
  }
}
function toPageClick(url) {
  router.push(url);
}
function logoutClick() {
  _data.showDialog = true;
  _data.tipTitle = "Logout";
  _data.tipText = "Are you sure you want to logout?";
}
function logOutConfirm() {
  publicStore.setIsLogin(false);
  publicStore.setReferralCode("");
  delCookie("access_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  _data.showDialog = false;
  router.push("/login");
}
function sidebarClick() {
  if (!isLogin.value) {
    return;
  }
  emits("sidebar:update");
}
function sidebarInfoClick() {
  publicStore.setHaveNewNews2(false)
  emits("sidebarinfo:update");
}
</script>

<style lang="scss" scoped>
::v-deep .v-toolbar {
  left: 0 !important;
  width: 100% !important;
}
.create-webhook {
  color:#3FA2F6;
  font-size: 12px;
}
.avatar-box {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #555;
  text-align: center;
  line-height: 48px;
  font-size: 18px;
  font-weight: 600;
}
.row-menu {
  cursor: pointer;
  padding: 8px 16px;
  width: 100%;
  &:hover {
    //   background: #0b23497a;
    background: #4949492e;
  }
}
.code-box {
  border-radius: 8px;
  border: 1px solid #222;
  background-color: #171717;
  .code-box-left {
    max-width: 70%;
  }
}
.header-component {
  height: 100%;
  width: 100%;
  background: #121212;
  border-bottom: 1px solid #ffffff21;
  .link {
    text-decoration: none;
    font-weight: 300;
    &:hover {
      color: #48ac75;
    }
  }
  .btn-label {
    font-size: 12px;
    color: #fff;
    font-weight: 300;
  }
  .start-new-btn {
    background-image: linear-gradient(to right, #54ad80, #26a757);
    border: 1px solid #3fdfe7;
    box-shadow: 0 0 10px 1px #3fdfe7;
    padding: 5px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    &:hover {
      background-image: linear-gradient(to right, #4fc489, #4ac679);
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
    &:hover::before {
      -webkit-animation: sh02 0.5s 0s linear;
      -moz-animation: sh02 0.5s 0s linear;
      animation: sh02 0.5s 0s linear;
    }
    &::before {
      content: "";
      display: block;
      width: 0px;
      height: 86%;
      position: absolute;
      top: 7%;
      left: 0%;
      opacity: 0;
      background: #fff;
      box-shadow: 0 0 50px 30px #fff;
      -webkit-transform: skewX(-20deg);
      -moz-transform: skewX(-20deg);
      -ms-transform: skewX(-20deg);
      -o-transform: skewX(-20deg);
      transform: skewX(-20deg);

      //   -webkit-animation: sh02 0.5s 0s linear;
      //   -moz-animation: sh02 0.5s 0s linear;
      //   animation: sh02 0.5s 0s linear;
    }
    @keyframes sh02 {
      from {
        opacity: 0;
        left: 0%;
      }

      50% {
        opacity: 1;
      }

      to {
        opacity: 0;
        left: 100%;
      }
    }
  }
}
.pc-header,
.mobile-header,
.pc-content {
  height: 100%;
}
@media (max-width: 768px) {
  .pc-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
}
@media (min-width: 768px) {
  .pc-header {
    display: block;
  }
  .mobile-header {
    display: none;
  }
}

@keyframes text-ani-before{
  0% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(24px 0 30px 0);
  }
}
@keyframes text-ani-after{
  0% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(30px 0 24px 0);
  }
}
.invite-text {
  display: inline-block;
  font-weight: 600;
  padding: 0 4px;
  color: white;
  position: relative;
  font-size: 14px;
  &::before{
    content: attr(data-text);
    position: absolute;
    left: -2px;
    width: 100%;
    background: #121212;
    text-shadow:2px 0 rgb(31, 179, 187);
    animation: text-ani-before 3s infinite linear alternate-reverse;
  }
  &::after{
    content: attr(data-text);
    position: absolute;
    left: 2px;
    width: 100%;
    background: #121212;
    text-shadow: -2px 0 blue;
    animation: text-ani-after 3s infinite linear alternate-reverse;
  }
}
.particletext {
  text-align:center;
  font-size:48px;
  position: relative;
  &.confetti {
    >.particle {
      opacity:0;
      position: absolute;
      animation: confetti 3s ease-in infinite;
      &.c1 {
          background-color:rgba(76,175,80,0.5);
      }
      &.c2 {
          background-color:rgba(156,39,176,0.5);
      }
    }
  }
}

@keyframes confetti {
   0% {
      opacity:0;
      transform:translateY(0%) rotate(0deg);
   }
   10% {
      opacity:1;
   }
   35% {
      transform:translateY(-800%) rotate(270deg);
   }
   80% {
      opacity:1;
   }
   100% {
      opacity:0;
      transform:translateY(2000%) rotate(1440deg);
   }
}
$t: 1s;

@property --k {
	syntax: '<number>';
	initial-value: -1;
	inherits: true
}

.user-name {
	--m: 1;
	grid-area: 2/ 1/ span 1/ span 2;
	position: relative;
  height: 34px;
  padding: 0 20px;
	// margin: 1em;
	// border: solid 2em transparent;
	// padding: 1.5em 3.25em;
	border-radius: 5em;
	// box-shadow: inset 0 0 1px #23429e;
	// background: 
	// 	radial-gradient(#111a39, 67.5%, #3476da) padding-box, 
	// 	radial-gradient(#a0c2ed, 35%, #a0c2ed00 70%) 50% 0/ 80% 50% repeat-y;
  box-shadow: inset 0 0 1px #48ade3;
	background: 
		radial-gradient(#111a39, 67.5%, #4c87de) padding-box, 
		radial-gradient(#7fabe6, 35%, #4aae9a00 70%) 50% 0/ 80% 50% repeat-y;
	color: #f2fdfe;
	// font: 700 1.5em/ 1 montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing:1px;
	
	#slow:checked ~ & { --m: 5 }
	
	&:active { translate: 0 2px }
}

@keyframes k { 0%, 33.3% { --k: 1 } }

.particle {
	--f: 1;
	--pos-k: max(0, var(--k));
	--neg-k: max(0, -1*var(--k));
	--low-c: min(1, 4*(1 - var(--pos-k)));
	--abs-d: max(var(--neg-k) - .5, .5 - var(--neg-k));
	--mov-f: var(--pos-k);
	display: grid;
	position: absolute;
	left: var(--x);
	top: var(--y);
	rotate: var(--a);
	animation: k calc(var(--m) * #{$t} * 10) ease-in-out calc(var(--m) * var(--t, 0) * #{$t} * 10) infinite;
	
	@supports (scale: sqrt(4)) { --mov-f: sqrt(var(--pos-k)) }
	
	&::before,
	&::after {
		grid-area: 1 / 1;
		width: 0.75em;
		aspect-ratio: 1;
	}
	
	&::before {
		--sa: calc(min(1, 1 - 2 * min(0.5, var(--mov-f))) * 45deg);
		border-radius: calc(1.25 * min(0.8, var(--mov-f)) * 50%) 50% 50%;
		transform-origin: 0 0;
		translate: calc(var(--mov-f) * var(--d));
		rotate: -45deg;
		scale: var(--f);
		transform: skew(var(--sa), var(--sa));
		opacity: var(--low-c);
		filter: saturate(var(--low-c));
		background: radial-gradient(at 85% 85%, #bad9fa, #3e66a4 75%);
		content: '';
	}
	
	&::after {
		translate: -50% -50%;
		scale: calc(var(--f) * (1 - 2 * var(--abs-d)));
		text-align: center;
		filter: blur(0.5px);
		content: '✦';
	}
}


[type='checkbox'] {
	justify-self: end;
	margin: 5px;
	width: 1em;
	aspect-ratio: 1;
	
	&, & + label {
		align-self: center;
		font: 1.25em orbitron, monospace;
		cursor: pointer
	}
}

.vip-tag {
  font-size: 12px;
  padding: 1px 10px;
  background: radial-gradient(#111a39, 67.5%, #4c87de) padding-box,
              radial-gradient(#7fabe6, 35%, rgba(74, 174, 154, 0) 70%) 50% 0 / 80% 50% repeat-y;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  margin-left: 8px;
  font-weight: 600;
  margin: 0 auto;
}
</style>